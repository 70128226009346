const INITIAL_STATE: any = {
  id_Link: 0,
  id_categorie: 0,
  openDialog: false,
  categorie: [],
  links: [],
  boxDragging: false,
};

const StateLinks = (state = INITIAL_STATE, action: any): any => {
  switch (action.type) {
    case "CATEGORIE": {
      const data = {
        ...state,
        categorie: action.payload,
      };

      localStorage.setItem("my-favory", JSON.stringify(data));

      return data;
    }
    case "LINKS": {
      const data = {
        ...state,
        links: action.payload,
      };

      localStorage.setItem("my-favory", JSON.stringify(data));

      return data;
    }
    case "OPENDIALOG": {
      return {
        ...state,
        openDialog: action.payload,
      };
    }
    case "ID_LINK": {
      return {
        ...state,
        id_link: action.payload,
      };
    }
    case "ID_CATEGORIE": {
      return {
        ...state,
        id_categorie: action.payload,
      };
    }
    case "BOX_DRAGGING": {
      return {
        ...state,
        boxDragging: action.payload,
      };
    }
    
    default:
      return state;
  }
};

export default StateLinks;
