import { combineReducers } from 'redux';
import { RootState } from '../store';
import StateLinks from './links';


const createRootReducer = () =>
  // eslint-disable-next-line implicit-arrow-linebreak
  combineReducers({
    link: StateLinks,
  });

const rootReducer = createRootReducer();

export default rootReducer;

export const linkState = (state: RootState) => state.link;

/**
 * used by selectors
 * @param state
 * @param path
 * @param [errorMessageIfNotFound]
 * @returns {*}
 */

export const getData = (state: RootState, path: string, errorMessageIfNotFound?: any) => {

  let data;

  try {

    if (typeof state === 'function') {
      throw new Error(
        'The state parameter must not be a function. The error is usually the usage of getState instead of getState(). Path is',
      );
    }

    data = path.split('.').reduce((res: any, prop) => res[prop], state);

    if (errorMessageIfNotFound && data == null) {
      throw new Error(errorMessageIfNotFound);
    }

  } catch (error) {
    return null;
  }
  
  return data;
};
