import { FC, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import useLinks from "../../Hooks/links";
import { useStyles } from "./style";

interface IDialogEdit {
  open: boolean;
  setOpen: (props: boolean) => void;
}

const DialogEdit: FC<IDialogEdit> = ({ open, setOpen }) => {
  const classe = useStyles();

  const { id_link, id_categorie, links, setLinks, setIdLink, setIdCategorie } =
    useLinks();

  const [current, setCurrent] = useState<any>({});

  useEffect(() => {
    if (open) {
      const find = links.find((item: any) => item.id === id_link);

      setCurrent(find);
    } else {
      setCurrent({});
    }
  }, [open, links, setCurrent, id_link]);

  const handleClose = () => {
    setOpen(false);
    setIdLink(0);
    setIdCategorie(0);
    setCurrent({});
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setCurrent({
      ...current,
      [name]: value,
    });
  };

  const update = () => {
    const updateLinks = links.map((item: any) => {
      if (item.id === id_link) {
        return {
          ...current,
        };
      }

      return item;
    });

    setLinks(updateLinks);
  };

  const create = () => {
    const updateLinks = links || [];

    updateLinks.push({
      ...current,
      link: !current.link.includes("http")
        ? `https://${current.link}`
        : current.link,
      parent: id_categorie,
      id: links.length + 1,
    });

    setLinks(updateLinks);
  };

  const comfirm = async () => {
    if (current?.parent) {
      update();
    } else {
      create();
    }

    setIdLink(0);

    setIdCategorie(0);

    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} className={classe.dialog}>
      <h2 className={classe.title}>
        {current?.parent ? "Edit" : "Add"} your link
      </h2>

      <div>
        <input
          name="title"
          onChange={handleChange}
          placeholder="Title"
          value={current?.title || ""}
          className={classe.textFied}
        />

        <input
          name="link"
          onChange={handleChange}
          value={current?.link || ""}
          placeholder="Your link"
          className={classe.textFied}
        />
      </div>

      <div className={classe.btnContent}>
        <div onClick={handleClose} className={classe.btnCanceled}>
          <p>Canceled</p>
        </div>
        <div className={classe.btnComfirm} onClick={comfirm}>
          <p>Comfirm</p>
        </div>
      </div>
    </Dialog>
  );
};

export default DialogEdit;
