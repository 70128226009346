import { FC, useCallback, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { ItemTypes } from "../../Utils/ItemTypes";
import useStyles from "./style";
import type { Identifier, XYCoord } from "dnd-core";
import { useRef } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { IconButton } from "@material-ui/core";
import useLinks from "../../Hooks/links";
import clsx from "clsx";

export interface IBoxCategorie {
  id: number;
  children?: any;
  moveCard: (id: number, to: number) => void;
  index: number;
  title: string;
  open: boolean;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const BoxCategorie: FC<IBoxCategorie> = ({
  id,
  children,
  moveCard,
  index,
  title,
  open,
}) => {
  const classe = useStyles();

  const { links, categorie, setCategorie, setLinks, set_box_dragging } =
    useLinks();

  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.BOX,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex: number = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) /
        hoverBoundingRect.height;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: ItemTypes.BOX,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });

  const opacity = isDragging ? 0 : 1;

  drop(ref);

  const handleDelete = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();

      categorie.splice(index, 1); // 2nd parameter means remove one item only

      setLinks(links.filter((x: any) => x.parent !== +id));

      setCategorie(categorie);
    },
    [links, categorie, setLinks, setCategorie, index, id]
  );

  const handleChange = (id_category: number) => (e: any) => {
    const value = e.target.value;

    const filterCategorie = categorie.map((y: any) => {
      if (y.id === id_category) {
        return {
          ...y,
          title: value,
        };
      }

      return {
        ...y,
      };
    });

    setCategorie(filterCategorie);
  };

  const handleTohgle = (id_category: number) => (e: any) => {
    const filterCategorie = categorie.map((y: any) => {
      if (y.id === id_category) {
        return {
          ...y,
          open: !y?.open,
        };
      }

      return {
        ...y,
      };
    });

    setCategorie(filterCategorie);
  };

  useEffect(() => {
    set_box_dragging(isDragging);
  }, [isDragging, set_box_dragging]);

  return (
    <div ref={ref}>
      <div
        style={{ opacity }}
        data-handler-id={handlerId}
        className={classe.root}
      >
        {/* <Accordion square={true} expanded={open} onChange={handleTohgle(id)}> */}
        <div
          className={classe.head}
          ref={dragPreview}
        >
          <div ref={drag} className={classe.contentInput}>
            <DragIndicatorIcon className={classe.iconDrag} />
            <input
              type="text"
              className={classe.textField}
              placeholder="Your title"
              onChange={handleChange(id)}
              value={title}
            />
          </div>
          <div>
            {!links.filter((x: any) => x.parent === +id).length && (
              <IconButton onClick={handleDelete}>
                <img
                  width="35"
                  height="35"
                  src="https://img.icons8.com/sf-black-filled/64/delete-forever.png"
                  alt="delete-forever"
                />
              </IconButton>
            )}
            <IconButton onClick={handleTohgle(id)}>
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </IconButton>
          </div>
        </div>
        <div
          className={clsx({
            [classe.children]: true,
            [classe.toogleOpen]: open,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default BoxCategorie;
