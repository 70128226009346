/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import AppContent from '../Page/App';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import useLinks from '../Hooks/links';
import { useEffect } from 'react';
import { Children, Parent } from '../Utils/emptyLinks';

const RouteComponent = () => {

    const { setCategorie, setLinks } = useLinks();

    useEffect(() => {
        const myFavory = localStorage.getItem("my-favory") || "";
        if (myFavory) {
            const lasted = JSON.parse(myFavory);
            setLinks(lasted.links || []);
            setCategorie(lasted.categorie || []);
        } else {
            setLinks(Children);
            setCategorie(Parent);
        }
    }, [])

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" component={AppContent} exact={true} />
            </Switch>
        </BrowserRouter>
    );
}

export default RouteComponent;
