/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable function-paren-newline, no-extra-boolean-cast */
import { useCallback } from "react";
import { LinkAction } from "../store/actions";
import { linkState } from "../store/reducers";
import { ILink, ICategorie, Ilinks } from "../store/types";
import { useDispatch, useSelector } from "react-redux";

const useLinks = () => {
  const dispatch = useDispatch();

  const data: Ilinks = useSelector(linkState);

  const setLinks = (props: ILink[] | []) => {
    dispatch(LinkAction.setLinks(props));
  };

  const setCategorie = (props: ICategorie[] | []) => {
    dispatch(LinkAction.setCategorie(props));
  };

  const setOpenDialog = (props: boolean) => {
    dispatch(LinkAction.setOpenDialog(props));
  };

  const setIdLink = (props: number) => {
    dispatch(LinkAction.setIdLink(props));
  };

  const setIdCategorie = (props: number) => {
    dispatch(LinkAction.setIdCategorie(props));
  };

  const set_box_dragging = useCallback((props: boolean) => {
    dispatch(LinkAction.set_box_dragging(props));
  }, [dispatch]);

  return {
    ...data,
    setLinks,
    setIdLink,
    setCategorie,
    setOpenDialog,
    setIdCategorie,
    set_box_dragging,
  };
};

export default useLinks;
