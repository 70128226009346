import { AppContainer } from './AppContainer'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';
import Logo from '../../Component/Logo';
import ProviderDnd from '../../Component/ProviderDnd';
import useStyles from "./style";

const DragAndDrop = () => {

    const classe = useStyles();

    return (
        <ProviderDnd>

            <Logo />

            <DndProvider backend={HTML5Backend}>
                <div className={classe.center}>
                    <AppContainer />
                </div>
            </DndProvider>

            <p className={classe.textCenter}>
                ©2023, my-favory.com, Inc.All rights reserved. App to create by <a href='http://www.ranaivomanana-tahiana.com'>RANAIVOMANANA Tahiana</a>
            </p>

        </ProviderDnd>
    )

}

export default DragAndDrop;