import { FC, useCallback } from "react";
import update from "immutability-helper";
import { memo } from "react";
import Box from "../../Component/Box";
import DialogEdit from "../../Component/Dialog";
import AddBox from "../../Component/AddBox";
import useLinks from "../../Hooks/links";
import { Links } from "./Links";
import BoxCategorie from "./BoxCategorie";

export const AppContainer: FC = memo(function AppContainer() {
  const { categorie, boxDragging, setCategorie, openDialog, setOpenDialog } =
    useLinks();

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const item: any[] = categorie || [];

      const x = update(item, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, item[dragIndex] as any],
        ],
      });

      setCategorie(x);
    },
    [categorie, setCategorie]
  );

  const renderCard = (card: any, index: number) => {
    return (
      <BoxCategorie
        key={card.id}
        open={card?.open}
        id={card?.id || 0}
        moveCard={moveCard}
        index={index}
        title={card.title || ""}
      >
        {!boxDragging && (
          <Box id_category={card.id}>
            <Links id_categorie={card.id} />
          </Box>
        )}
      </BoxCategorie>
    );
  };

  return (
    <div>
      {categorie.map((item: any, i: number) => renderCard(item, i))}
      <AddBox />
      <DialogEdit open={openDialog} setOpen={setOpenDialog} />
    </div>
  );
});
