import { FC, useState, MouseEvent } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import useLinks from "../../Hooks/links";

const options = ["Edit", "Delete"];

const ITEM_HEIGHT = 48;

interface IMenuLink {
  id_Link: number;
  id_categorie: number;
}

const MenuLink: FC<IMenuLink> = ({ id_Link, id_categorie }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { links, setOpenDialog, setIdCategorie, setIdLink, setLinks } =
    useLinks();

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e?: any) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };

  const selected = (value: string) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (value === "Edit") {
      setOpenDialog(true);
      setIdLink(id_Link);
      setIdCategorie(id_categorie);
    }

    if (value === "Delete") {
      const lastedList = links.filter((x: any) => x.id !== id_Link);
      setLinks(
        lastedList.map((x: any, index: number) => {
          return {
            ...x,
            id: index + 1,
          };
        })
      );
    }

    setAnchorEl(null);
  };

  return (
    <div>
      <div onClick={handleClick}>
        <MoreVertIcon />
      </div>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            border: "4px solid black",
            borderRadius: "18px",
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
            padding: 0,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={selected(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default MenuLink;
