import type { FC } from 'react'
import { useDrop } from 'react-dnd'
import { useStyles } from './style';
import { ItemTypes } from '../../Utils/ItemTypes';
import clsx from 'clsx';

interface IBox {
  id_category: any;
  children: any;
}

const TargetBox: FC<IBox> = ({
  id_category, children
}) => {

  const classe = useStyles();

  const [{ isActive }, drop] = useDrop(() => ({
    accept: ItemTypes.CARD,
    drop: () => ({ id_category: id_category }),
    collect: (monitor) => ({
      isActive: monitor.canDrop() && monitor.isOver(),
    }),
  }))

  return (
    <div
      ref={drop}
      className={clsx({
        [classe.root]: true,
        [classe.backgroundSeleted]: isActive
      })}
    >
      {children}
    </div>
  )
}

export default TargetBox;
