import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '95%',
        borderRadius: '1vh',
        margin: '1vh auto',
    },
    backgroundSeleted: {
        background: 'rgba(220,220,220,0.2)'
    }
}));