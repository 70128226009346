import { ThemeProvider } from "@material-ui/core/styles";
import myTheme from "./Themes/myTheme";
import { Provider } from "react-redux";
import { store } from "./store/store";
import RouteComponent from "./Route";

function App() {
  return (
    <>
      <Provider store={store}>
        <ThemeProvider theme={myTheme}>
          <RouteComponent />
        </ThemeProvider>
      </Provider>
      ;
    </>
  );
}

export default App;
