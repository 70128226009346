import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      lert: "5vh",
      width: "100%",
      height: "auto",
      paddingTop: "1vh",
      paddingBottom: "1vh",
      border: "5px dashed #BCBCBC",
      borderRadius: "24px",
      overflow: "hidden",
      cursor: "pointer",
      margin: "1vh auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "500ms",
      "&:hover": {
        border: "5px dashed black",
      },
    },
  })
);

export default useStyles;
