/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable import/prefer-default-export */
import { AppThunk } from "../store";

export const setLinks =
  (data: any): AppThunk =>
  (dispatch) => {
    dispatch({
      type: "LINKS",
      payload: data,
    });
  };

export const setCategorie =
  (data: any): AppThunk =>
  (dispatch) => {
    dispatch({
      type: "CATEGORIE",
      payload: data,
    });
  };

export const setOpenDialog =
  (data: boolean): AppThunk =>
  (dispatch) => {
    dispatch({
      type: "OPENDIALOG",
      payload: data,
    });
  };

export const setIdLink =
  (data: number): AppThunk =>
  (dispatch) => {
    dispatch({
      type: "ID_LINK",
      payload: data,
    });
  };

export const setIdCategorie =
  (data: number): AppThunk =>
  (dispatch) => {
    dispatch({
      type: "ID_CATEGORIE",
      payload: data,
    });
  };

export const set_box_dragging =
  (data: boolean): AppThunk =>
  (dispatch) => {
    dispatch({
      type: "BOX_DRAGGING",
      payload: data,
    });
  };
