import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    head: {
      width: "98%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "0vh auto",
      paddingTop: "1vh",
      borderRadius: "24px",
      overflow: "hidden",
    },
    iconDrag: {
      cursor: "move",
    },
    root: {
      width: "100%",
      backgroundColor: "white",
      borderRadius: "24px",
      transition: "100ms",
      margin: "5vh auto",
      paddingBottom: "1vh",
      boxShadow: "0 0 1px 5px",
      [theme.breakpoints.down("xs")]: {
        margin: "3vh auto",
      },
    },
    textCenter: {
      textAlign: "center",
    },
    center: {
      width: "50%",
      transition: "50ms",
      margin: "0 auto",
      [theme.breakpoints.down("lg")]: {
        width: "50%",
      },
      [theme.breakpoints.down(1600)]: {
        width: "70%",
      },
      [theme.breakpoints.down(1310)]: {
        width: "80%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "95%",
      },
    },
    contentInput: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      width: "50%",
    },
    textField: {
      width: "100%",
      border: "none",
      background: "transparent",
      padding: "1.5vh",
      fontSize: "25px",
      height: "10px",
      borderRadius: "1.5vh",
      [theme.breakpoints.down("xs")]: {
        width: "230px",
      },
    },
    children: {
      position: "relative",
      transition: "500",
      paddingBottom: "1.5vh",
    },
    toogleOpen: {
      height: 0,
      transition: "500",
      overflow: "hidden",
      paddingBottom: 0,
    },
    links: {
      display: "flex",
      flexWrap: "wrap",
    },
  })
);

export default useStyles;
