import update from "immutability-helper";
import { FC, useEffect, useState } from "react";
import { memo, useCallback } from "react";
import { useDrop } from "react-dnd";
import Link from "../../Component/Link";
import useLinks from "../../Hooks/links";
import { ItemTypes } from "../../Utils/ItemTypes";
import AddLink from "../../Component/AddLink";
import useStyles from "./style";

const style = {
  width: "100%",
};

interface ILinks {
  id_categorie: number;
}

export const Links: FC<ILinks> = memo(function Links({ id_categorie }) {
  const classe = useStyles();

  const { links, setLinks } = useLinks();

  const [canComfirm, setCanComfirm] = useState(false);

  const [dataFromLink, setDataFromLink] = useState<any>(null);

  const findCard = useCallback(
    (id: string) => {
      const card = links.filter((c) => `${c.id}` === id)[0];
      return {
        card,
        index: links.indexOf(card),
      };
    },
    [links]
  );

  const moveCard = useCallback(
    (id: string, atIndex: number, parent: number, isHover: boolean) => {
      const { card, index } = findCard(id);

      const newArray = links.map((item: any) => {
        if (item.id === id && item.parent !== parent) {
          return {
            ...item,
            parent,
          };
        }

        return item;
      });

      const arrayAfterChange = update(newArray, {
        $splice: [
          [index, 1],
          [
            atIndex,
            0,
            {
              ...card,
              parent,
              isHover: isHover,
            },
          ],
        ],
      });

      setLinks(arrayAfterChange);
    },
    [findCard, links, setLinks]
  );

  const [, drop] = useDrop(() => ({
    accept: ItemTypes.CARD,
  }));

  useEffect(() => {
    if (canComfirm) {
      const findLink = links.find(
        (x: any) => x.id === dataFromLink?.id_currentLink
      );

      const verifyIfEmpty = findLink?.parent !== dataFromLink?.id_category;

      const arrayFormated = links.map((item: any) => {
        return {
          id: item.id,
          title: item.title,
          parent:
            verifyIfEmpty && findLink?.id === item.id
              ? dataFromLink?.id_category
              : item.parent,
          link: item.link || "",
        };
      });

      setLinks(arrayFormated);

      setCanComfirm(false);
    }
  }, [canComfirm, dataFromLink, links, setLinks]);

  const comfirmPlace = useCallback((data: any) => {
    setCanComfirm(true);
    setDataFromLink(data);
  }, []);

  return (
    <div ref={drop} style={{ ...style }} className={classe.links}>
      {links
        .filter((x: any) => x.parent === id_categorie)
        .map((card) => (
          <Link
            key={`${card.id}`}
            id={`${card.id}`}
            data={card}
            moveCard={moveCard}
            findCard={findCard}
            parent={id_categorie}
            isHover={!!card.isHover}
            comfirmPlace={comfirmPlace}
          />
        ))}

      <AddLink id_categorie={id_categorie} />
    </div>
  );
});
