import { FC } from 'react'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import useLinks from '../../Hooks/links';
import useStyles from './style';

interface IAddLink {
    id_categorie: number;
}

const AddLink: FC<IAddLink> = ({ id_categorie }) => {

    const classe = useStyles();

    const {
        setOpenDialog,
        setIdCategorie
    } = useLinks();

    const handleOpenDialg = () => {
        setOpenDialog(true);
        setIdCategorie(id_categorie);
    }

    return (
        <div
            className={classe.root}
            onClick={handleOpenDialg}
        >
            <div
                className={classe.content}
            >
                <AddCircleOutlineIcon />
            </div>
        </div>
    )
}

export default AddLink;