import useStyles from "./style";
import { FC } from "react";


const Logo: FC = () => {

    const classe = useStyles();

    return (
        <div
            className={classe.root}
        >
            <img
                src="/IMG/logo.png"
                className={classe.img}
                alt="logo"
            />
        </div>
    );
};

export default Logo;