import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      padding: "1.5vh",
    },
    img: {
      marginTop: "2vh",
      width: 50,
      height: 50,
    },
  })
);

export default useStyles;
