import { FC } from 'react'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import useLinks from '../../Hooks/links';
import useStyles from './style';


const AddLink: FC = () => {

    const classe = useStyles();

    const {
        links,
        setLinks,
        categorie,
        setCategorie
    } = useLinks();

    const handleAddBox = () => {

        const id = categorie.length + 1;

        categorie.push({
            id: id,
            title: "",
        })

        setLinks(links.filter((x: any) => x.parent !== id));
        
        setCategorie(categorie)
    }

    return (
        <div
            className={classe.root}
            onClick={handleAddBox}
        >
            <AddCircleOutlineIcon />
        </div>
    )
}

export default AddLink;