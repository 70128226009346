import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "calc(16.66% - 10px)", // Adjust the width as needed
      paddingBottom: "calc(16.66% - 10px)",
      backgroundColor: "#f0f0f0",
      boxSizing: "border-box",
      borderRadius: "24px",
      overflow: "hidden",
      cursor: "pointer",
      margin: "0.5%",
      position: "relative",
      transition: "200ms",
      border: "5px dashed #BCBCBC",
      "&:hover": {
        border: "5px dashed black",
      },
      [theme.breakpoints.down("sm")]: {
        width: "calc(33.33% - 10px)", // Adjust the width as needed
        paddingBottom: "calc(33.33% - 10px)",
      },
      [theme.breakpoints.down("xs")]: {
        width: "calc(49%)", // Adjust the width as needed
        paddingBottom: "calc(49%)",
      },
    },
    content: {
      position: "absolute",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

export default useStyles;
