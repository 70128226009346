import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

const myTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#000A8C',
      dark: '#0E1247',
    },
    secondary: {
      main: '#FFDC27',
      dark: '#E63C3C',
    },
    warning: {
      main: '#962A57',
    },
  },
  typography: {
    fontFamily: ['ITC Avant Garde Gothic Std Bold', 'sans-serif'].join(','),
    fontSize: 16,
    htmlFontSize: 16,
    h1: {
      fontFamily: '"ITC Avant Garde Gothic Std Bold", sans-serif',
      fontSize: '30px',
      fontWeight: 700,
      textTransform: 'uppercase',
      color: "#2b56a5 !important",
    },
    h2: {
      fontFamily: '"ITC Avant Garde Gothic Std Bold", sans-serif',
      fontSize: '1.5625rem',
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    h3: {
      fontFamily: '"ITC Avant Garde Gothic Std Bold", sans-serif',
      fontSize: '1.25rem',
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    h4: {
      fontFamily: '"ITC Avant Garde Gothic Std Bold", sans-serif',
      fontSize: '1.125rem',
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    subtitle1: {
      fontFamily: '"ITC Avant Garde Gothic Std Bold", sans-serif',
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: 1.25,
      textTransform: 'uppercase',
    },
    body1: {
      fontFamily: '"ITC Avant Garde Gothic Std Medium", sans-serif',
      fontSize: '1rem',
    },
    body2: {
      fontFamily: '"ITC Avant Garde Gothic Std Medium", sans-serif',
      fontSize: '0.875rem',
    },
    caption: {
      fontFamily: '"ITC Avant Garde Gothic Std Medium", sans-serif',
      fontSize: '0.75rem',
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        // backgroundColor: 'transparent',
      },
      elevation2: {
        boxShadow: '0 4px 4px rgba(0,0,0,.25)',
      },
    },
    
    MuiSelect: {
      iconOutlined: {
        color: '#FFFFFF',
      },
    },
    MuiButton: {
      root: {
        height: 41,
        fontSize: '0.875rem',
        textTransform: 'uppercase',
        fontFamily: 'ITC Avant Garde Gothic Std Medium',
      },
      text: {
        fontWeight: 700,
      },
      contained: {
        fontWeight: 600,
      },
      containedPrimary: {
        background: 'linear-gradient(113deg, #C346FE 0%, #8852FF 48%, #415EFF 100%)',
      },
    },
    MuiInputBase: {
      root: {
        '& input': {
          fontFamily: 'ITC Avant Garde Gothic Std Medium',
          letterSpacing: 0,
          '&::placeholder': {
            color: '#9C9DB7',
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            letterSpacing: 0,
          },
        },
      },
    },
    MuiFormGroup: {
      root: {
        fontFamily: 'ITC Avant Garde Gothic Std Medium',
        letterSpacing: 0,
      },
    },
    MuiFormControlLabel: {
      root: {
        fontFamily: 'ITC Avant Garde Gothic Std Medium',
        letterSpacing: 0,
      },
    },
    MuiTableCell: {
      root: {
        fontFamily: 'ITC Avant Garde Gothic Std Medium',
        letterSpacing: 0,
      },
    },
    MuiTypography: {
      root: {
        fontFamily: 'ITC Avant Garde Gothic Std Medium',
        letterSpacing: 0,
      },
    },
  },
});

export default myTheme;
