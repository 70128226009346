// eslint-disable-next-line no-unused-vars
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      "& .MuiDialog-paperWidthSm": {
        minWidth: "400px",
        borderRadius: "24px",
        padding: "1%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: "5px solid black",
        [theme.breakpoints.down("sm")]: {
          minWidth: "60%",
        },
        [theme.breakpoints.down("xs")]: {
          minWidth: "90%",
        },
      },
    },
    textFied: {
      position: "relative",
      width: "80%",
      border: "5px solid black",
      background: "transparent",
      padding: "1.5vh",
      fontSize: "25px",
      height: "15px",
      borderRadius: "1.5vh",
      margin: "1vh auto",
      left: "6%",
      [theme.breakpoints.down("xs")]: {
        width: "85%",
        left: "2.5%",
      },
    },
    btnContent: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    btnCanceled: {
      border: "5px solid #BCBCBC",
      boxShadow: "none",
      background: "transparent",
      fontSize: "20px",
      width: "150px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      height: "40px",
      borderRadius: "24px",
      margin: "1vh",
    },
    btnComfirm: {
      background: "#4CECF3",
      fontSize: "20px",
      width: "150px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      height: "40px",
      border: "5px solid #4CECF3",
      borderRadius: "24px",
      margin: "1vh",
      "&.makeStyles-btnComfirm-287": {
        fontFamily: "Baloo Bhaina 2",
      },
    },
    title: { fontFamily: "Baloo Bhaina 2" },
  })
);
