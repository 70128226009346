export const Parent: any[] = [
    {
        id: 1,
        title: 'My work',
    },
    {
        id: 2,
        title: 'Other Link',
    },
];

export const Children: any[] = [
    {
        "id": 1,
        "title": "Slack",
        "link": "https://slack.com",
        "parent": 1
    },
    {
        "id": 2,
        "title": "My mail at work",
        "link": "https://mail.google.com",
        "parent": 1
    },
    {
        "id": 3,
        "title": "Daily",
        "parent": 1,
        "link": "https://meet.google.com"
    },
    {
        "id": 4,
        "title": "Notion",
        "parent": 1,
        "link": "https://www.notion.so"
    },
    {
        "link": "https://trello.com/",
        "title": "Trello",
        "parent": 1,
        "id": 5
    },
    {
        "title": "Figma",
        "link": "https://www.figma.com/",
        "parent": 1,
        "id": 6
    },
    {
        "link": "https://docs.google.com/spreadsheets/u/0/",
        "title": "Google Sheet",
        "parent": 1,
        "id": 7
    },
    {
        "link": "https://www.linkedin.com/",
        "title": "Linkedin",
        "parent": 1,
        "id": 8
    },
    {
        "link": "https://lucidspark.com/",
        "title": "My note",
        "parent": 1,
        "id": 9
    },
    {
        "link": "google.com/intl/fr/drive/",
        "title": "Drive",
        "parent": 1,
        "id": 10
    },
    {
        "link": "https://discord.com/",
        "title": "Discord",
        "parent": 1,
        "id": 11
    },
    {
        "link": "https://www.youtube.com/",
        "title": "Youtube",
        "parent": 2,
        "id": 12
    }
];