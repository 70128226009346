import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "calc(16.66% - 10px)", // Adjust the width as needed
      paddingBottom: "calc(16.66% - 10px)",
      backgroundColor: "#f0f0f0",
      boxSizing: "border-box",
      borderRadius: "24px",
      overflow: "hidden",
      cursor: "pointer",
      margin: "0.5%",
      transition: "200ms",
      position: "relative",
      border: "5px solid #f0f0f0",
      "&:hover": {
        border: "5px solid black",
      },
      [theme.breakpoints.down("sm")]: {
        width: "calc(33.33% - 10px)", // Adjust the width as needed
        paddingBottom: "calc(33.33% - 10px)",
      },
      [theme.breakpoints.down("xs")]: {
        width: "calc(49%)", // Adjust the width as needed
        paddingBottom: "calc(49%)",
      },
    },
    img: {
      width: 30,
      height: 30,
    },
    head: {
      zIndex: 5,
      position: "absolute",
      top: "5%",
      width: "99%",
      margin: "0 auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    iconDrag: {
      cursor: "move",
    },
    content: {
      zIndex: 3,
      gap: 0,
      position: "absolute",
      top: "25%",
      width: "100%",
      height: "60%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      textAlign: "center",
    },
    typography: {
      fontFamily: "Baloo Bhaina 2",
      maxWidth: "100px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  })
);

export default useStyles;
