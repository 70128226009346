import { FC, useState } from "react";
import { memo } from "react";
import { useDrag, useDrop } from "react-dnd";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { ItemTypes } from "../../Utils/ItemTypes";
import useStyles from "./style";
import Menu from "../Menu";
import Tooltip from "@material-ui/core/Tooltip";

const cleaneUrl = (url: string) => {
  if (url !== "") {
    let cleanedUrl: any = url.includes("https")
      ? url.slice(8)
      : url.includes("http") && url.slice(7);

    cleanedUrl = (cleanedUrl || "").includes("/")
      ? (cleanedUrl || "").split("/")[0]
      : cleanedUrl;

    return cleanedUrl;
  }

  return "";
};

export interface ILink {
  id: string;
  parent: number;
  data: any;
  moveCard: (id: string, to: number, parent: number, isHover: boolean) => void;
  findCard: (id: string) => { index: number };
  isHover: boolean;
  comfirmPlace: (data: any) => void;
}

interface Item {
  id: string;
  originalIndex: number;
}

const Link: FC<ILink> = memo(function Link({
  id,
  data,
  moveCard,
  parent,
  findCard,
  isHover,
  comfirmPlace,
}) {
  const [viewEdit, setViewEdit] = useState(false);

  const classe = useStyles();

  const originalIndex = findCard(id).index;

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: ItemTypes.CARD,
      item: { id, originalIndex, parent },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        comfirmPlace({ ...monitor.getDropResult(), id_currentLink: +id });
      },
    }),
    [id, originalIndex, moveCard]
  );

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      hover({ id: draggedId }: Item) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id);
          moveCard(draggedId, overIndex, parent, true);
        }
      },
    }),
    [findCard, moveCard]
  );

  const opacity = isHover || isDragging ? 0 : 1;

  const urlRedirect = data.link ? data.link : "https://www.google.com";

  const redirect = () => {
    window.open(urlRedirect, "_blank");
  };

  const siteUrl = cleaneUrl(urlRedirect);

  const onMouseOver = () => {
    setViewEdit(true);
  };

  const onMouseLeave = () => {
    setViewEdit(false);
  };

  return (
    <Tooltip title={data.title}>
      <div
        ref={drop}
        style={{ opacity }}
        className={classe.root}
        onClick={redirect}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      >
        {viewEdit && (
          <div className={classe.head}>
            <div ref={drag}>
              <DragIndicatorIcon className={classe.iconDrag} />
            </div>
            <div>
              <Menu id_Link={data.id} id_categorie={data.parent} />
            </div>
          </div>
        )}

        <div className={classe.content}>
          <img
            ref={dragPreview}
            src={
              data.link
                ? `http://www.google.com/s2/favicons?domain=${siteUrl}&size=50`
                : "/IMG/logo.png"
            }
            className={classe.img}
            alt="img link"
          />
          <p className={classe.typography}>{data.title}</p>
        </div>
      </div>
    </Tooltip>
  );
});

export default Link;
